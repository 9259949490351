import styled, { css } from 'styled-components';

export const Button = styled.button`
  background: transparent;
  border: none;
`;

export const OptionButton = styled.button`
  width: 100%;
  height: 25px;
  border: none;
  background: white;
  box-sizing: border-box;
  z-index: 2;
  padding: 0 16px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #f8f8f8;
  }
  &:focus {
    outline: none;
  }
`;
