import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  navFilterSection: {
    width: '100%',
  },
  search: {
    backgroundColor: theme.palette.background.paper,
  },
  inputmask: {
    padding: '10px',
    height: '70%',
    width: '100%',
    fontSize: '16px',
    borderRadius: 5,
    border: '1px solid #c4c4c4',
    outline: '1px solid #F7F8FA',
    paddingLeft: '14px',
    backgroundColor: theme.palette.background.paper,
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  topBtns: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'flex-end',
  },
}));
