import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '&$selected': {
      backgroundColor: 'green',
      color: 'white',
    },
    '&$selected:hover': {
      backgroundColor: 'red',
      color: 'white',
    },
  },
  selected: {},
}));
