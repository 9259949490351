import React from 'react';
import { motion } from 'framer-motion';

const AnimateDiv = ({ children }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        height: 0,
      }}
      transition={{
        duration: 0.3,
        ease: [0.43, 0.13, 0.23, 0.96],
      }}
      animate={{
        opacity: 1,
        height: 40,
      }}
      exit={{
        opacity: 0,
        height: 0,
      }}
    >
      {children}
    </motion.div>
  );
};
export default AnimateDiv;
